import React from "react"
import QuizMaster from "../../../components/layouts/quiz_master"
import Settings from "../../../components/quiz/settings"
import ToggleList from "../../../components/quiz/toggle-list"
import Toggle from "../../../components/quiz/toggle"

const days = require("../../../components/japanese/data/days.json")

class Days extends React.Component  {
  constructor(props) {
    super(props);
    this.day = 0;
    this.lastday = 0;
    this.answer = "";
    this.correct = 0;
    this.incorrect = 0;
    this.limitSetting = true;
    this.newDay = this.newDay.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);

    this.state = {
        loaded: false,
        day: this.day,
        question: "",
        lastAnswer: "",
        status: "",
        wait: false,
        correct: this.correct,
        incorrect: this.incorrect,
        limitSetting: this.limitSetting,
    };
  }

  newDay() {
    let maxDays = this.limitSetting ? 31 : 10;
    this.day = QuizMaster.generateItem(function(){return Math.floor(Math.random() * maxDays)}, this.lastday);
    this.lastday = this.day;
    this.setState({ day: this.day, lastday: this.day, wait: false, status: "" });
    this.updateQuestion()
    this.updateAnswer()
  }

  limitToggle = e => {
    this.limitSetting = !this.limitSetting;
    this.setState({ limitSetting: this.limitSetting });
    this.newDay();
  }

  updateQuestion() {
    this.question = (this.day + 1).toString() + "日";
    this.setState({ question: this.question });
  }

  updateAnswer() {
    this.answer = days[this.day];
  }

  checkAnswer(userAnswer) {
    var status = "";
    if(this.answer === userAnswer || (Array.isArray(this.answer) && this.answer.includes(userAnswer))) {
      status = "correct";
      this.correct += 1;
    } else {
      status = "incorrect";
      this.incorrect += 1;
    }
    let lastAnswer = this.answer;
    this.setState({status: status, correct: this.correct, incorrect: this.incorrect, lastAnswer: lastAnswer, wait: true})
  }

  componentDidMount() {
    if(!this.state.loaded) {
      this.newDay()
      this.setState({ loaded: true });
    }
  }

  render() {
    return (
      <QuizMaster
        title="Japanese Days of the Month"
        description="Interactive Study tool for learning Japanese days of the month."
        question={this.state.question}
        wait={this.state.wait}
        status={this.state.status}
        onSubmit={this.checkAnswer}
        onClear={this.newDay}
        correct={this.state.correct}
        incorrect={this.state.incorrect}
        answer={this.state.lastAnswer}
        settings={
          <Settings>
            <ToggleList>
              <Toggle id="limitsetting" label="1-10 ⟷ 1-31" checked={this.state.limitSetting} onToggle={this.limitToggle} />
            </ToggleList>
          </Settings>
        }
      >      
    </QuizMaster>
    );
  }
}

export default Days
